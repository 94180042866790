<template>
    <div class="mt-2 mb-2">
        <div class="">
            <div class="flex mt-3 items-center">
                <div class="flex-initial tm-checkbox relative transition-transform duration-300 ease-in-out">
                    <span class="block w-14 h-8 border border-grey rounded-full shadow-inner bg-white"></span>
                    <span 
                        v-bind:class="{ checked: checked, unchecked: !checked }" 
                        class="absolute block w-6 h-6 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                    </span>
                </div>
                <div class="flex-grow ml-3">
                    <div class="text-lg sm:text-lg">
                        {{ optin.name }}
                    </div>
                    <div v-if="hasDescription(optin)">
                        <i>
                            {{ optin.description }}
                        </i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        optin: {
        type: Object,
        required: true
        }
    },
    computed: {
        title: function() {
            if (this.optin.name) {
                return this.optin.name;
            }
            return "";
        },
        checked: function() {
            if (this.optin.typeid === 40002) {
                return (this.optin.status === 7603);
            }
            return (this.optin.status === 7602);
        }
    },
    methods: {
        hasDescription(optin) {
            if (optin.description === '-') {
                return false;
            }
            return !!optin.description;
        },        
    },
}
</script>
<style scoped>
.unchecked {
    @apply bg-inactive;
}

.checked {
    @apply bg-primary transform translate-x-full;
}
</style>